<template>
  <div
    v-if="fact"
    class="info-panel"
    ref="infoPanel"
  >
    <button
      class="info-panel__btn"
      @click="show"
    >
      <i class="icon-info text-3xl text-white"></i>

      <span class="info-panel__orbit" />
    </button>

    <transition name="custom-fade">
      <div
        v-if="visible"
        class="info-panel__text p-6 2xl:p-8"
        :class="{'_active': visible}"
      >
        <button
          class="mb-4 w-9 h-9 border-2 border-white rounded-full
          bg-white text-blue text-sm font-black uppercase
          transition-colors icon-close
          hover:text-white hover:bg-green"
          aria-label="Close"
          @click="hide"
        />

        <h3
          class="mb-2 text-md font-bold 2xl:mb-3 2xl:text-3xl"
          data-aos="fade-in"
          data-aos-delay="100"
        >
          {{ fact.title }}
        </h3>

        <div
          v-html="fact.description"
          class="text-base 2xl:text-2xl font-light"
          data-aos="fade-in"
          data-aos-delay="200"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'InfoPanel',
  props: {
    fact: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      const delay = this.removeAnimations();

      setTimeout(() => {
        this.visible = false;
      }, delay);
    },
    removeAnimations() {
      let maxDelay;
      const animElements = this.$refs.infoPanel.querySelectorAll('.aos-init');

      animElements.forEach((el, index, arr) => {
        maxDelay = Math.max(...[...arr].map((elem) => elem.dataset.aosDelay));

        const delay = maxDelay - el.dataset.aosDelay;
        setTimeout(() => {
          el.classList.remove('aos-animate');
        }, delay);
      });

      return maxDelay;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_mixins';
@import '@/assets/scss/_variables';

.info-panel {
  position: relative;
  display: inline-block;

  &__btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid $color-white;
    border-radius: 50%;
    width: 3.75rem;
    height: 3.75rem;
    background-color: $color-clean-pool;

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: info-panel-pulse 3s ease infinite;
    }

    &::before {
      width: 160%;
      height: 160%;
      background: url('../assets/images/info-panel-circle-01.svg') no-repeat center / cover;
      animation-delay: 0s;
    }

    &::after {
      width: 180%;
      height: 180%;
      background: url('../assets/images/info-panel-circle-02.svg') no-repeat center / cover;
      animation-delay: 0.2s;
    }
  }

  &__orbit {
    position: absolute;
    top: -2rem;
    left: -2rem;
    right: -2rem;
    bottom: -2rem;
    animation: info-panel-rotations 120s linear infinite;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border: 3px solid $color-white;
      border-radius: 50%;
      width: 2.4rem;
      height: 2.4rem;
      background: $color-green url(../assets/images/icons/icon-plus.svg) no-repeat center / 50%;
      animation: info-panel-rotations 120s linear infinite reverse;
    }
  }

  &__text {
    position: absolute;
    z-index: 999;
    top: 50%;
    right: 0;
    transform: translate(50%, -35%);
    width: 18rem;
    background-color: $color-primary;
    color: $color-white;

    strong {
      font-weight: 700;
      color: $color-green;
    }

    @include respond-above(md_plus) {
      width: 20rem;
    }

    @include respond-above(xxl) {
      width: 30rem;
      transform: translate(39%, -50%);
    }
  }
}

@keyframes info-panel-pulse {
  0% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.25);
    opacity: 0.2;
  }
}

@keyframes info-panel-rotations {
  to {
    transform: rotate(360deg);
  }
}

.custom-fade-enter-active,
.custom-fade-leave-active {
  transition: opacity 0.35s
}

.custom-fade-enter,
.custom-fade-leave-to {
  opacity: 0
}
</style>
