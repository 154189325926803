<template>
  <div
    class="hotspot-dot"
    :title="hotspot.label"
    :style="{
      '--label-animation': animateLabel ? `rotations-label-${animationIndex}` : 'none',
      '--scale': scale,
    }"
    @click="openDetails(hotspot.solution)"
  >
    <div
      class="hotspot-dot__icon"
      :style="hotspotStyles"
    >
      <img
        :src="hotspot.icon"
        :alt="hotspot.label"
      />
      <div
        class="hotspot-dot__aura hotspot-dot__aura--green"
        style="--size: 115%"
      />
      <div
        class="hotspot-dot__aura hotspot-dot__aura--blue"
        style="--size: 130%"
      />
    </div>

    <div class="hotspot-dot__orbit">
      <span
        class="hotspot-dot__label"
        :class="{ 'hotspot-dot__label--transparent' : labelTransparent,
         'hotspot-dot__label--bottom': !animateLabel }"
      >
        {{ hotspot.label }}
      </span>

      <button
        v-if="!noPlus"
        class="hotspot-dot__plus"
      />
    </div>
  </div>
</template>

<script>
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default {
  name: 'HotspotDot',
  props: {
    hotspot: {
      type: Object,
      required: true,
    },
    labelTransparent: {
      type: Boolean,
      default: false,
    },
    noPlus: {
      type: Boolean,
      default: false,
    },
    animationDelay: {
      type: Number,
      default: 0,
    },
    scale: {
      type: Number,
      default: 1,
    },
    animateLabel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      animationIndex: getRandomInt(1, 3),
    };
  },
  computed: {
    hotspotStyles() {
      return {
        width: `calc(9vw * ${this.scale})`,
        height: `calc(9vw * ${this.scale})`,
      };
    },
  },
  methods: {
    openDetails(id) {
      this.$router.push({
        name: 'solutions.index',
        params: {
          solutionId: id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
/* eslint-disable */
@import '@/assets/scss/_mixins';
@import '@/assets/scss/_variables';

.hotspot-dot {
  cursor: pointer;
  --animation-delay: 0s;
  --animation-duration: 250s;
  user-select: none;

  &__icon {
    position: relative;
    border-radius: 50%;
    width: 9vw;
    height: 9vw;
    background-color: $color-blue;
    cursor: pointer;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .hotspot-dot__aura {
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: hotspot-dot-pulse 3s ease infinite;
      pointer-events: none;
      width: var(--size);
      height: var(--size);

      &--green {
        background: url('../assets/images/hotspot-dot-circle-01.svg') no-repeat center / contain;
        animation-delay: 0s;
      }

      &--blue {
        background: url('../assets/images/hotspot-dot-circle-02.svg') no-repeat center / contain;
        animation-delay: 0.2s;
      }
    }

  }

  &__orbit {
    position: absolute;
    top: -1rem;
    left: -1rem;
    right: -1rem;
    bottom: -1rem;
    border-radius: 50%;
  }

  &__label {
    position: absolute;
    z-index: 49;
    top: calc(50% - 1.5vw);
    left: 50%;
    transform-origin: left center;
    font-size: 1.25vw;
    line-height: 1;
    padding: 0.35vw 1vw;
    background-color: $color-white;
    box-shadow: 0 0.25rem 0.375rem -1px rgba($color-black, 0.1),
    0 0.125rem 0.25rem -1px rgba($color-black, 0.06);
    font-weight: 500;
    color: $color-black;
    white-space: nowrap;
    animation: var(--label-animation) var(--animation-duration) linear infinite;
    animation-delay: var(--animation-delay);

    &--transparent {
      background-color: #FFFFFB;
      box-shadow: none;
      font-size: 1vw;
      font-weight: 400;
    }

    &--bottom {
      transform-origin: center;
      top: 95%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__plus {
    position: absolute;
    top: calc(50% - 1vw);
    left: calc(50% - 1vw);
    border: 3px solid $color-white;
    border-radius: 50%;
    width: 2vw;
    height: 2vw;
    background: $color-green url(../assets/images/icons/icon-plus.svg) no-repeat center / 50%;
    box-shadow: 0 0.25rem 0.375rem -1px rgba($color-black, 0.1),
    0 0.125rem 0.25rem -1px rgba($color-black, 0.06);
    animation: rotations-plus-sign var(--animation-duration) linear infinite;
    animation-direction: reverse;

    @include respond-above(xxl) {
      width: 2.4vw;
      height: 2.4vw;
    }
  }
}

@keyframes rotations-label-5 {
  0% {
    transform: rotate(0deg) translateY(calc(7.29vw * var(--scale)));
  }
  50% {
    transform: rotate(-180deg) translateY(calc(7.29vw * var(--scale))) rotate(180deg);
  }
  100% {
    transform: rotate(0deg) translateY(calc(7.29vw * var(--scale)));
  }
}

@keyframes rotations-label-2 {
  0% {
    transform: rotate(0deg) translateY(calc(-6vw * var(--scale)))
    translateX(calc(3vw * var(--scale)));
  }
  50% {
    transform: rotate(150deg) translateY(calc(-6vw * var(--scale)))
    translateX(calc(3vw * var(--scale))) rotate(-150deg);
  }
  10% {
    transform: rotate(-30deg) translateY(calc(-6vw * var(--scale)))
    translateX(calc(3vw * var(--scale))) rotate(30deg);
  }
  100% {
    transform: rotate(0deg) translateY(calc(-6vw * var(--scale)))
    translateX(calc(3vw * var(--scale)));
  }
}

@keyframes rotations-label-3 {
  0% {
    transform: rotate(0deg) translateY(0vw) translateX(calc(6vw * var(--scale)));
  }
  25% {
    transform: rotate(45deg) translateY(calc(5.5vw * var(--scale)))
    translateX(calc(5.1vw * var(--scale))) rotate(-45deg);
  }
  50% {
    transform: rotate(0deg) translateX(calc(6vw * var(--scale)));
  }
  75% {
    transform: rotate(-45deg) translateY(calc(-5.5vw * var(--scale)))
    translateX(calc(5.1vw * var(--scale))) rotate(45deg);
  }
  100% {
    transform: rotate(0deg) translateY(0vw) translateX(calc(6vw * var(--scale)));
  }
}

@keyframes rotations-label-4 {
  0% {
    transform: rotate(0deg) translateY(6vw) translateX(3vw);
  }
  10% {
    transform: rotate(30deg) translateY(calc(6vw * var(--scale)))
    translateX(calc(3vw * var(--scale))) rotate(-30deg);
  }
  50% {
    transform: rotate(-150deg) translateY(calc(6vw * var(--scale)))
    translateX(calc(3vw * var(--scale))) rotate(150deg);
  }
  100% {
    transform: rotate(0deg) translateY(calc(6vw * var(--scale)))
    translateX(calc(3vw * var(--scale)));
  }
}

@keyframes none {}

@keyframes rotations-label-1 {
  0% {
    transform: rotate(0deg) translateY(calc(-7.29vw * var(--scale)));
  }
  50% {
    transform: rotate(180deg) translateY(calc(-7.29vw * var(--scale))) rotate(-180deg);
  }
  100% {
    transform: rotate(0deg) translateY(calc(-7.29vw * var(--scale)));
  }
}

@keyframes rotations-plus-sign {
  0% {
    transform: rotate(0deg) translateY(5vw) translateX(-3vw);
  }
  50% {
    transform: rotate(140deg) translateY(5vw) translateX(-3vw) rotate(-140deg);
  }
  100% {
    transform: rotate(0deg) translateY(5vw) translateX(-3vw);
  }
}

@keyframes hotspot-dot-pulse {
  0% {
    width: calc(var(--size) + 20px);
    height: calc(var(--size) + 20px);
    opacity: 1;
  }

  100% {
    width: calc(var(--size) + 10px);
    height: calc(var(--size) + 10px);
    opacity: 0.2;
  }
}
</style>
