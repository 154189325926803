import Vue from 'vue';
import VueRouter from 'vue-router';

import { routes as homeRoutes } from '@/views/home';
import { routes as solutionsRoutes } from '@/views/solutions';
import { routes as getInTouchRoutes } from '@/views/get-in-touch';
import { routes as stadiumRoutes } from '@/views/stadium';
import { routes as videoRoutes } from '@/views/video';
import { routes as concourseViewRoutes } from '@/views/concourse-view';
import { routes as notFoundRoutes } from '@/views/not-found';

Vue.use(VueRouter);

const routes = [
  ...homeRoutes,
  ...solutionsRoutes,
  ...getInTouchRoutes,
  ...stadiumRoutes,
  ...videoRoutes,
  ...concourseViewRoutes,
  ...notFoundRoutes,
];

const router = new VueRouter({
  mode: 'history',
  routes,
  base: process.env.VUE_APP_BASE_URL,
});

export default router;
