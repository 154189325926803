<template>
  <div
    class="flex flex-col p-4 2xl:py-6 shadow-xl bg-white text-black transition-colors"
  >
    <div
      class="mb-5 2xl:mb-5 relative flex rounded-full
             w-16 h-16 overflow-hidden"
    >
      <img
        :src="solution.media.icon"
        alt=""
        class="m-auto max-h-full"
      />
    </div>

    <h4
      class="mb-2 text-sm 2xl:text-lg font-black uppercase leading-tight"
    >
      {{ solution.title }}
    </h4>

    <h3
      class="mb-5 2xl:mb-12 flex-auto text-xl 2xl:text-2xl font-light leading-tight"
    >
      {{ solution.subtitle }}
    </h3>

    <div>
      <label
        :for="`toggle-${solution.uid}`"
        class="toggle flex flex-col xl:flex-row xl:items-center cursor-pointer"
      >
        <div class="relative">
          <input
            v-model="model"
            type="checkbox"
            :id="`toggle-${solution.uid}`"
            class="sr-only"
          />

          <div
            class="block w-16 h-7 border-2 rounded-full"
            :class="model ? 'border-green' : 'border-gray-power'"
          />

          <div
            class="dot absolute left-1 top-1 w-5 h-5 rounded-full
                   flex items-center justify-center transition-all text-white text-xs"
            :class="model ? 'bg-green icon-switch-active' : 'bg-gray-power icon-switch-inactive'"
          />
        </div>

        <span
          class="xl:px-3 select-none text-xs 2xl:text-base font-normal"
        >
          Send me this
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    solution: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  watch: {
    model(currentValue) {
      this.$emit('input', currentValue);
    },
  },
};
</script>
