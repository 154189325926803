<template>
  <div
    id="app"
    class="h-screen overflow-hidden"
  >
    <loader v-if="loading" />

    <template v-else>
      <app-header :settings='$route.meta.header' />
      <transition :name="transitionName">
        <router-view />
      </transition>
    </template>
  </div>
</template>

<script>
import AppHeader from '@/layouts/default/app-header.vue';
import { Loader } from '@/components';
import store, { actions } from '@/store';

export default {
  metaInfo() {
    return {
      title: this.$route.meta.title,
      titleTemplate: '%s | Global Pay',
    };
  },
  components: {
    Loader,
    AppHeader,
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    store.dispatch(actions.content.loadContent).then(() => { this.loading = false; });
    setInterval(() => {
      store.dispatch(actions.content.loadContent);
    }, process.env.VUE_APP_API_FETCH_INTERVAL);
  },
  computed: {
    transitionName() {
      return this.$route.meta.transition || '';
    },
  },
};
</script>

<style lang="scss" src="./assets/scss/app.scss"></style>
