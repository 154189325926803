import { home } from '@/constants/route-names';

const component = () => import('./home.view.vue');

export default [
  {
    path: '/',
    name: home.index,
    component,
    meta: {
      title: 'Home',
      header: {
        hide: true,
      },
    },
  },
];
