<template>
  <transition
    enter-active-class="fade-in"
    leave-active-class="fade-out"
  >
    <v-hotspot-dot
      v-if="transform"
      :hotspot="hotspot"
      :style="'transform: ' + transform + '; z-index: ' + zIndex"
      class="absolute"
      label-transparent
      :scale="scale"
      no-plus
      :animate-label="false"
    />
  </transition>
</template>

<script>
import * as THREE from 'three';
import HotspotDot from './hotspot-dot.vue';

export default {
  name: 'HotspotWrapper',
  components: {
    VHotspotDot: HotspotDot,
  },
  props: {
    hotspot: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      transform: null,
      zIndex: null,
      timeId: null,
      z: 0,
      scale: 1,
    };
  },
  computed: {
    camera() {
      return this.$parent.camera;
    },
    viewport() {
      return this.$parent.viewport;
    },
    model() {
      return this.$parent.gltfModel;
    },
    target() {
      if (this.model) {
        const hotspots = this.model.children.find((obj) => obj.name === 'hotspots');
        return hotspots.children.find((hotspot) => hotspot.name.split('-')[1] === this.hotspot.object.split('-')[1]);
      }
      return false;
    },
  },
  methods: {
    getPosition() {
      if (this.model !== undefined && this.target) {
        const tempV = new THREE.Vector3();
        this.target.updateWorldMatrix(true, false);
        this.target.getWorldPosition(tempV);
        tempV.project(this.camera);

        const x = (tempV.x * 0.5 + 0.5) * this.viewport.width;
        const y = (tempV.y * -0.5 + 0.5) * this.viewport.height;
        const scale = (y / this.viewport.height) + 0.5;
        this.scale = scale >= 1 ? 1 : scale;

        this.transform = `translate(-50%, -50%) translate(${x}px,${y + 40}px)`;
        // eslint-disable-next-line no-bitwise
        this.zIndex = Math.trunc((-tempV.z * 0.5 + 0.5) * 10000);
      }
    },
  },
  mounted() {
    this.timeId = setInterval(this.getPosition, 50);
  },
  beforeDestroy() {
    clearInterval(this.timeId);
  },
};
</script>
