<template>
  <div class="border border-white min-h-full p-4 xl:pt-6 xl:pb-16 2xl:p-6
            bg-white text-black-abaddon"
  >
    <div class="mb-4 2xl:mb-8 w-12 h-12 flex">
      <img
        src="@/assets/images/icons/check.svg"
        alt=""
        class="m-auto w-full"
      />
    </div>

    <div class="max-h-36 pr-2 overflow-auto custom-scrollbar">
      <div
        class="text-sm 2xl:text-xl font-normal"
        v-html="benefit"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    benefit: {
      type: String,
      required: true,
    },
  },
};
</script>
<style>

</style>
