<template>
  <div
    v-if="product"
    class="fixed top-0 left-0 z-110 w-screen h-screen bg-blue text-white"
  >
    <img
      src="@/assets/images/product-bg.svg"
      alt=""
      class="absolute top-0 right-0 h-full object-contain"
    />

    <router-link
        to="/"
        class="absolute left-8 top-8"
      >
        <img
          src="@/assets/images/logo-white.svg"
          alt="Global Pay"
          class="w-14 transition-opacity hover:opacity-80"
        />
    </router-link>

    <div class="container mx-auto px-8">
      <div class="relative h-screen pt-16 pb-16 lg:pb-24 flex">
        <div
          class="relative z-10 flex-initial w-1/2
                 pt-4 lg:pt-12 pr-4 2xl:pr-8 flex flex-col justify-center"
        >
          <div class="max-w-lg xl:max-w-xl">
            <button
              class="mb-5 w-9 h-9 border-2 border-white rounded-full
              bg-white text-blue text-sm font-black uppercase
              transition-colors icon-close
              hover:bg-blue hover:text-white"
              aria-label="Back"
              @click="hide"
            />

            <h3 class="mb-6 text-3xl 2xl:text-5xl font-bold text-white">
              {{ product.title }}
            </h3>

            <div class="max-h-40vh pr-4 overflow-auto custom-scrollbar">
              <div
                v-html="product.description"
                class="mb-10 max-w-md text-sm 2xl:text-lg text-block"
              />

              <div>
                <table
                  v-if="product.spec"
                  class="table-auto text-sm 2xl:text-base"
                >
                  <tbody>
                    <tr
                      v-for="(specItem, specIndex) in product.spec"
                      :key="specIndex"
                      :class="{'border-b-2 border-green' : specIndex !== product.spec.length - 1}"
                    >
                      <template v-if="specItem.title">
                        <td
                          v-html="specItem.value"
                          class="py-2 font-bold"
                        />
                      </template>

                      <template v-else>
                        <td class="border-r-2 border-green py-2 pr-3 font-bold">
                          {{ specItem.key }}
                        </td>

                        <td
                          v-html="specItem.value"
                          class="py-2 pl-3"
                        />
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-initial w-1/2 flex flex-col justify-center pt-12 xl:pt-0">
          <div class="relative min-h-fit flex">
            <img
              src="@/assets/images/kite-mask.svg"
              alt=""
              class="absolute top-1/2 left-0 transform -translate-y-1/2 w-full max-h-full"
            />

            <img
              v-if="product.media.image"
              :src="product.media.image"
              alt=""
              class="relative m-auto w-10/12 max-h-fit object-contain"
            />
          </div>
        </div>
      </div>
    </div>

    <footer class="fixed z-50 left-0 bottom-0 w-full px-8 py-8">
      <div class="flex items-center justify-between">
        <div class="min-w-30vw flex items-center">
          <a
            class="mr-3 inline-flex items-center text-base 2xl:text-lg text-white
                  cursor-pointer transition-colors hover:text-green"
            @click="hideAll"
          >
            <i
              class="inline-flex items-center justify-center border-2 border-white rounded-full
                    w-8 2xl:w-12 h-8 2xl:h-12 p-2
                    icon-arrow-left text-white mr-2"
            />
            Back
            <span class="hidden xl:inline xl:ml-2">
               to <span class="ml-1 font-bold">{{ solutionTitle }} overview</span>
            </span>
          </a>

          <button
            class="mr-2 border-2 border-white rounded-3xl py-1 px-4
            bg-blue text-sm 2xl:text-xl font-medium text-white
            transition-colors
            hover:bg-white hover:text-blue
            focus:outline-none focus:bg-white focus:text-blue"
            @click="hide"
          >
            Benefits
          </button>
        </div>

        <router-link
          to="/get-in-touch"
          class="mx-3 border-2 border-white rounded-full py-2 lg:py-6 px-5 lg:px-12
          bg-blue text-sm 2xl:text-xl font-bold text-white
          transition-colors
          hover:bg-white hover:text-blue
          focus:outline-none focus:bg-white focus:text-blue"
        >
          Contact Us
        </router-link>

        <div class="min-w-30vw"></div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getters } from '@/store';

export default {
  name: 'ProductOverlay',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      solutionId: this.$route.params.solutionId,
    };
  },
  computed: {
    ...mapGetters([
      getters.content.solutions,
    ]),
    solutionTitle() {
      return this.solutions.find((s) => s.uid === this.solutionId).title || '';
    },
  },
  methods: {
    hide() {
      this.$emit('hide');
    },
    hideAll() {
      this.$emit('hide-all');
    },
    show() {
      this.$emit('show');
    },
  },
};
</script>
