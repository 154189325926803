<template>
  <div class="scroll-progress">
    <div
      class="scroll-progress__bar"
      :style="{ width: `${scrolled}%` }"
    />
  </div>
</template>

<script>
export default {
  props: {
    scrolled: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.scroll-progress {
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.75rem;
  background-color: #d9d9d9;

  &__bar {
    width: 0%;
    height: 100%;
    background-color: #316df6;
    transition: width 0.1s;
  }
}
</style>
