var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed z-40 top-0 left-0 w-screen h-screen bg-blue text-white"},[_c('img',{staticClass:"absolute bottom-0 object-contain",attrs:{"src":require("@/assets/images/get-in-touch-bg.svg"),"alt":""}}),_c('div',{staticClass:"container mx-auto px-4"},[_c('div',{staticClass:"relative z-10 h-screen flex px-4 2lx:px-0"},[_c('div',{staticClass:"flex-initial w-1/2\n               pr-8 flex items-center text-white lg:pr-32"},[_c('div',{staticClass:"max-w-sm"},[_c('h2',{staticClass:"mb-3 2xl:mb-6 text-3xl 2xl:text-5xl",domProps:{"innerHTML":_vm._s(_vm.contact.details.title)}}),_c('div',{staticClass:"max-h-30vh pr-4 overflow-auto custom-scrollbar"},[_c('div',{staticClass:"text-sm 2xl:text-lg",domProps:{"innerHTML":_vm._s(_vm.contact.details.text)}})])])]),_c('div',{staticClass:"flex-initial w-1/2 pl-8 2xl:pl-12 flex flex-col justify-center"},[_c('div',[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"}},[_c('form',{staticClass:"space-y-12"},[_c('div',{staticClass:"max-w-xl"},[_c('div',{staticClass:"mb-5 2xl:mb-7"},[_c('label',{staticClass:"text-base 2xl:text-lg",attrs:{"for":"company"}},[_vm._v(" Company * ")]),_c('ValidationProvider',{staticClass:"relative",attrs:{"name":"Company","rules":_vm.validationRules.company,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.company),expression:"model.company"}],staticClass:"appearance-none rounded-none block w-full px-3 py-3 border\n                      border-gray placeholder-gray-500 text-black\n                      transition-colors\n                      hover:border-black\n                      focus:outline-none focus:border-black sm:text-sm",attrs:{"id":"company","name":"company","type":"text"},domProps:{"value":(_vm.model.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "company", $event.target.value)}}}),_c('div',{staticClass:"absolute text-xs text-white"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"mb-5 2xl:mb-7"},[_c('label',{staticClass:"text-base 2xl:text-lg",attrs:{"for":"name"}},[_vm._v(" First Name * ")]),_c('ValidationProvider',{staticClass:"relative",attrs:{"name":"First Name","rules":_vm.validationRules.name,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.name),expression:"model.name"}],staticClass:"appearance-none rounded-none block w-full px-3 py-3 border\n                      border-gray placeholder-gray-500 text-black\n                      transition-colors\n                      hover:border-black\n                      focus:outline-none focus:border-black sm:text-sm",attrs:{"id":"name","name":"name","type":"text"},domProps:{"value":(_vm.model.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "name", $event.target.value)}}}),_c('div',{staticClass:"absolute text-xs text-white"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"mb-5 2xl:mb-7"},[_c('label',{staticClass:"text-base 2xl:text-lg",attrs:{"for":"surname"}},[_vm._v(" Surname * ")]),_c('ValidationProvider',{staticClass:"relative",attrs:{"name":"Surname","rules":_vm.validationRules.surname,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.surname),expression:"model.surname"}],staticClass:"appearance-none rounded-none block w-full px-3 py-3 border\n                      border-gray placeholder-gray-500 text-black\n                      transition-colors\n                      hover:border-black\n                      focus:outline-none focus:border-black sm:text-sm",attrs:{"id":"surname","name":"surname","type":"text"},domProps:{"value":(_vm.model.surname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "surname", $event.target.value)}}}),_c('div',{staticClass:"absolute text-xs text-white"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',[_c('label',{staticClass:"text-base 2xl:text-lg",attrs:{"for":"emailAddress"}},[_vm._v(" Email Address * ")]),_c('ValidationProvider',{staticClass:"relative",attrs:{"name":"Email","rules":_vm.validationRules.email,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.email),expression:"model.email"}],staticClass:"appearance-none rounded-none block w-full px-3 py-3 border\n                      border-gray placeholder-gray-500 text-black\n                      transition-colors\n                      hover:border-black\n                      focus:outline-none focus:border-black sm:text-sm",attrs:{"id":"emailAddress","name":"emailAddress","type":"email"},domProps:{"value":(_vm.model.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "email", $event.target.value)}}}),_c('div',{staticClass:"absolute text-xs text-white"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])])])],1)])])]),_c('footer',{staticClass:"absolute z-40 left-0 bottom-0 w-full px-8 py-8"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"min-w-30vw"},[_c('a',{staticClass:"inline-flex items-center text-base 2xl:text-lg text-white\n                 cursor-pointer transition-colors hover:text-green",on:{"click":_vm.prev}},[_c('i',{staticClass:"inline-flex items-center justify-center\n                    border-2 border-white rounded-full\n                    w-8 2xl:w-12 h-8 2xl:h-12 p-2\n                    icon-arrow-left text-white mr-2"}),_vm._v(" Back ")])]),_c('button',{staticClass:"mx-3 border-2 border-white rounded-full\n                py-2 lg:py-6 px-5 lg:px-12 bg-blue\n                text-sm 2xl:text-xl font-bold text-white\n                transition-colors\n              hover:bg-white hover:text-blue\n                focus:outline-none focus:bg-white focus:text-blue",on:{"click":_vm.submitHandler}},[_vm._v(" Confirm and proceed ")]),_c('div',{staticClass:"min-w-30vw"})])])])}
var staticRenderFns = []

export { render, staticRenderFns }