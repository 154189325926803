<template>
  <div class="fixed z-40 top-0 left-0 w-screen h-screen bg-blue">
    <img
      src="@/assets/images/get-in-touch-bg.svg"
      alt=""
      class="absolute bottom-0 object-contain"
    />

    <div class="w-full px-8">
      <div class="relative z-10 h-screen flex">
        <div
          class="flex-initial w-1/2 py-8 pr-8 flex items-center text-white lg:pr-32"
        >
          <div class="max-w-lg">
            <h2
              class="mb-6 font-light text-3xl 2xl:text-5xl"
              v-html="contact.thanks.title"
            />

            <div class="mb-6 max-h-30vh pr-4 overflow-auto custom-scrollbar">
              <div
                class="max-w-md text-sm 2xl:text-lg"
                v-html="contact.thanks.text"
              />
            </div>
          </div>
        </div>
        <div class="absolute right-0 h-full flex items-center">
          <img
            src="@/assets/images/cycle.svg"
            alt=""
            class="h-1/2 2xl:h-2/3 pr-6"
          />
        </div>
      </div>
    </div>

    <footer class="absolute z-40 left-0 bottom-0 w-full px-8 py-8">
      <div class="flex items-center justify-center">
        <button
          class="mx-3 border-2 border-white rounded-full
                  py-2 lg:py-6 px-5 lg:px-12 bg-blue
                  text-sm 2xl:text-xl font-bold text-white
                  transition-colors
                hover:bg-white hover:text-blue
                  focus:outline-none focus:bg-white focus:text-blue"
          @click="backToStadium"
        >
          Back to stadium
        </button>

        <div></div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getters } from '@/store';

export default {
  name: 'ThankYou',
  computed: {
    ...mapGetters([
      getters.content.contact,
    ]),
  },
  methods: {
    async backToStadium() {
      await this.$router.push('/stadium');
    },
  },
};
</script>
