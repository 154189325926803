import Vue from 'vue';
import VueCarousel from 'vue-carousel';
import VTooltip from 'v-tooltip';
import {
  required,
  email,
} from 'vee-validate/dist/rules';
import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';
import Meta from 'vue-meta';
import AOS from 'aos';
import App from './app.vue';
import './register-service-worker';
import router from './router';
import store from './store';

import 'aos/dist/aos.css';

Vue.use(VueCarousel);
Vue.use(VTooltip);

Vue.use(Meta, {
  refreshOnceOnNavigation: true,
});

Vue.use(AOS.init({
  duration: 600,
}));

localize({
  en: {
    messages: {
      email: 'The {_field_} field must be a valid email',
      required: 'The {_field_} field is required',
    },
  },
});
localize('en');

extend('required', required);
extend('email', email);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
