import { getInTouch } from '@/constants/route-names';

const component = () => import('./get-in-touch.view.vue');

export default [
  {
    path: '/get-in-touch',
    name: getInTouch.index,
    component,
    meta: {
      title: 'Get in Touch',
      transition: 'fade-up',
      header: {
        light: true,
      },
    },
  },
];
