<template>
  <div class="fixed z-40 top-0 left-0 w-screen h-screen bg-blue text-white">
    <img
      src="@/assets/images/get-in-touch-bg.svg"
      alt=""
      class="absolute bottom-0 object-contain"
    />

    <div class="container mx-auto px-4">
      <div class="relative z-10 h-screen flex px-4 2lx:px-0">
        <div
          class="flex-initial w-1/2
                 pr-8 flex items-center text-white lg:pr-32"
        >
          <div class="max-w-sm">
            <h2
              class="mb-3 2xl:mb-6 text-3xl 2xl:text-5xl"
              v-html="contact.details.title"
            />

            <div class="max-h-30vh pr-4 overflow-auto custom-scrollbar">
              <div
                class="text-sm 2xl:text-lg"
                v-html="contact.details.text"
              />
            </div>
          </div>
        </div>

        <div class="flex-initial w-1/2 pl-8 2xl:pl-12 flex flex-col justify-center">
          <div>
            <ValidationObserver
              ref="observer"
              tag="div"
            >
              <form class="space-y-12">
                <div class="max-w-xl">
                  <div class="mb-5 2xl:mb-7">
                    <label for="company" class="text-base 2xl:text-lg">
                      Company *
                    </label>

                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Company"
                      :rules="validationRules.company"
                      tag="div"
                      class="relative"
                    >
                      <input
                        v-model="model.company"
                        id="company"
                        name="company"
                        type="text"
                        class="appearance-none rounded-none block w-full px-3 py-3 border
                        border-gray placeholder-gray-500 text-black
                        transition-colors
                        hover:border-black
                        focus:outline-none focus:border-black sm:text-sm"
                      >

                      <div class="absolute text-xs text-white">
                        {{ errors[0] }}
                      </div>
                    </ValidationProvider>
                  </div>

                  <div class="mb-5 2xl:mb-7">
                    <label for="name" class="text-base 2xl:text-lg">
                      First Name *
                    </label>

                    <ValidationProvider
                      v-slot="{ errors }"
                      name="First Name"
                      :rules="validationRules.name"
                      tag="div"
                      class="relative"
                    >
                      <input
                        v-model="model.name"
                        id="name"
                        name="name"
                        type="text"
                        class="appearance-none rounded-none block w-full px-3 py-3 border
                        border-gray placeholder-gray-500 text-black
                        transition-colors
                        hover:border-black
                        focus:outline-none focus:border-black sm:text-sm"
                      >

                      <div class="absolute text-xs text-white">
                        {{ errors[0] }}
                      </div>
                    </ValidationProvider>
                  </div>

                  <div class="mb-5 2xl:mb-7">
                    <label for="surname" class="text-base 2xl:text-lg">
                      Surname *
                    </label>

                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Surname"
                      :rules="validationRules.surname"
                      tag="div"
                      class="relative"
                    >
                      <input
                        v-model="model.surname"
                        id="surname"
                        name="surname"
                        type="text"
                        class="appearance-none rounded-none block w-full px-3 py-3 border
                        border-gray placeholder-gray-500 text-black
                        transition-colors
                        hover:border-black
                        focus:outline-none focus:border-black sm:text-sm"
                      >

                      <div class="absolute text-xs text-white">
                        {{ errors[0] }}
                      </div>
                    </ValidationProvider>
                  </div>

                  <div>
                    <label for="emailAddress" class="text-base 2xl:text-lg">
                      Email Address *
                    </label>

                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Email"
                      :rules="validationRules.email"
                      tag="div"
                      class="relative"
                    >

                      <input
                        v-model="model.email"
                        id="emailAddress"
                        name="emailAddress"
                        type="email"
                        class="appearance-none rounded-none block w-full px-3 py-3 border
                        border-gray placeholder-gray-500 text-black
                        transition-colors
                        hover:border-black
                        focus:outline-none focus:border-black sm:text-sm"
                      >

                      <div class="absolute text-xs text-white">
                        {{ errors[0] }}
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>

    <footer class="absolute z-40 left-0 bottom-0 w-full px-8 py-8">
      <div class="flex items-center justify-between">
        <div class="min-w-30vw">
          <a
            class="inline-flex items-center text-base 2xl:text-lg text-white
                   cursor-pointer transition-colors hover:text-green"
            @click="prev"
          >
            <i
              class="inline-flex items-center justify-center
                      border-2 border-white rounded-full
                      w-8 2xl:w-12 h-8 2xl:h-12 p-2
                      icon-arrow-left text-white mr-2"
            />
            Back
          </a>
        </div>

        <button
          class="mx-3 border-2 border-white rounded-full
                  py-2 lg:py-6 px-5 lg:px-12 bg-blue
                  text-sm 2xl:text-xl font-bold text-white
                  transition-colors
                hover:bg-white hover:text-blue
                  focus:outline-none focus:bg-white focus:text-blue"
          @click="submitHandler"
        >
          Confirm and proceed
        </button>

        <div class="min-w-30vw"></div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { actions, getters } from '@/store';
import { auth as authApi } from '@/services/api';

function getDefaultModel() {
  return {
    name: '',
    surname: '',
    company: '',
    email: '',
  };
}

function getValidationRules() {
  return {
    name: {
      required: true,
    },
    surname: {
      required: true,
    },
    company: {
      required: true,
    },
    email: {
      required: true,
      email: true,
    },
  };
}
export default {
  name: 'ContactInfo',
  data() {
    return {
      model: getDefaultModel(),
      validationRules: getValidationRules(),
    };
  },
  computed: {
    ...mapGetters([
      getters.content.contact,
      getters.content.experienceUid,
      getters.contactForm.selected,
    ]),
  },
  methods: {
    ...mapActions([
      actions.contactForm.selectMany,
    ]),
    prev() {
      this.$emit('prev');
    },
    next() {
      this.$emit('next');
    },
    async submitHandler() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      const payload = {
        experience: this.experienceUid,
        ...this.model,
        solutions: this.selected,
      };

      try {
        await fetch(
          process.env.VUE_APP_API_CREATE_LEAD_PATH, {
            method: 'POST',
            headers: authApi.getAuthHeader(),
            body: JSON.stringify(payload),
          },
        );

        this.model = getDefaultModel();
        this.$refs.observer.reset();
        this[actions.contactForm.selectMany]([]);
        this.next();
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
