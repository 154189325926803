const home = {
  index: 'home.index',
};

const benefits = {
  index: 'benefits.index',
};

const getInTouch = {
  index: 'get-in-touch.index',
};

const solutions = {
  index: 'solutions.index',
};

const stadium = {
  index: 'stadium.index',
};

const technicalSpecifications = {
  index: 'technical-specifications.index',
};

const video = {
  index: 'video.index',
};

const notFound = {
  index: 'notFound.index',
};

export {
  home,
  benefits,
  getInTouch,
  solutions,
  stadium,
  technicalSpecifications,
  video,
  notFound,
};
