import { video } from '@/constants/route-names';

const component = () => import('./video.view.vue');

export default [
  {
    path: '/video',
    name: video.index,
    component,
    meta: {
      title: 'Video',
      transition: 'fade-up',
      header: {
        light: true,
      },
    },
  },
];
