import { stadium } from '@/constants/route-names';

const component = () => import('./stadium.view.vue');

export default [
  {
    path: '/stadium',
    name: stadium.index,
    component,
    meta: {
      title: 'Stadium',
      transition: 'fade-in',
    },
  },
];
