<template>
  <header
    v-if="!settings.hide"
    class="fixed z-100"
  >
    <div class="px-8 py-8">
      <router-link
        to="/"
        class="inline-block"
      >
        <img
          v-if="settings.light || showLightIcon"
          src="@/assets/images/logo-white.svg"
          alt="Global Pay"
          class="inline-block w-14 transition-opacity hover:opacity-80"
        />

        <img
          v-else
          src="@/assets/images/logo.svg"
          alt="Global Pay"
          class="inline-block w-14 transition-opacity hover:opacity-80"
        />

      </router-link>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { getters } from '@/store';

export default {
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters([getters.navigation.showLightIcon]),
  },
};
</script>
