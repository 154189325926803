import foodAndBeverageBg1 from '@/assets/images/solutions/food-and-beverage/bg-01.svg';
import foodAndBeverageBg2 from '@/assets/images/solutions/food-and-beverage/bg-02.svg';
import foodAndBeverageBg3 from '@/assets/images/solutions/food-and-beverage/bg-03.svg';
import foodAndBeverageBg4 from '@/assets/images/solutions/food-and-beverage/bg-04.svg';
import foodAndBeverageBg5 from '@/assets/images/solutions/food-and-beverage/bg-05.svg';

import backOfficeBg1 from '@/assets/images/solutions/back-office/bg-01.svg';
import backOfficeBg2 from '@/assets/images/solutions/back-office/bg-02.svg';
import backOfficeBg3 from '@/assets/images/solutions/back-office/bg-03.svg';
import backOfficeBg4 from '@/assets/images/solutions/back-office/bg-04.svg';
import backOfficeBg5 from '@/assets/images/solutions/back-office/bg-05.svg';

import fanEngagementBg1 from '@/assets/images/solutions/fan-engagement/bg-01.svg';
import fanEngagementBg2 from '@/assets/images/solutions/fan-engagement/bg-02.svg';
import fanEngagementBg3 from '@/assets/images/solutions/fan-engagement/bg-03.svg';
import fanEngagementBg4 from '@/assets/images/solutions/fan-engagement/bg-04.svg';
import fanEngagementBg5 from '@/assets/images/solutions/fan-engagement/bg-05.svg';

import menuBoardsBg1 from '@/assets/images/solutions/menu-boards/bg-01.svg';
import menuBoardsBg2 from '@/assets/images/solutions/menu-boards/bg-02.svg';
import menuBoardsBg3 from '@/assets/images/solutions/menu-boards/bg-03.svg';
import menuBoardsBg4 from '@/assets/images/solutions/menu-boards/bg-04.svg';
import menuBoardsBg5 from '@/assets/images/solutions/menu-boards/bg-05.svg';

import mobilePurchasingBg1 from '@/assets/images/solutions/mobile-purchasing/bg-01.svg';
import mobilePurchasingBg2 from '@/assets/images/solutions/mobile-purchasing/bg-02.svg';
import mobilePurchasingBg3 from '@/assets/images/solutions/mobile-purchasing/bg-03.svg';
import mobilePurchasingBg4 from '@/assets/images/solutions/mobile-purchasing/bg-04.svg';
import mobilePurchasingBg5 from '@/assets/images/solutions/mobile-purchasing/bg-05.svg';

import retailBg1 from '@/assets/images/solutions/retail/bg-01.svg';
import retailBg2 from '@/assets/images/solutions/retail/bg-02.svg';
import retailBg3 from '@/assets/images/solutions/retail/bg-03.svg';
import retailBg4 from '@/assets/images/solutions/retail/bg-04.svg';
import retailBg5 from '@/assets/images/solutions/retail/bg-05.svg';

import selfServiceBg1 from '@/assets/images/solutions/self-service/bg-01.svg';
import selfServiceBg2 from '@/assets/images/solutions/self-service/bg-02.svg';
import selfServiceBg3 from '@/assets/images/solutions/self-service/bg-03.svg';
import selfServiceBg4 from '@/assets/images/solutions/self-service/bg-04.svg';
import selfServiceBg5 from '@/assets/images/solutions/self-service/bg-05.svg';

import suiteCateringBg1 from '@/assets/images/solutions/suite-catering/bg-01.svg';
import suiteCateringBg2 from '@/assets/images/solutions/suite-catering/bg-02.svg';
import suiteCateringBg3 from '@/assets/images/solutions/suite-catering/bg-03.svg';
import suiteCateringBg4 from '@/assets/images/solutions/suite-catering/bg-04.svg';
import suiteCateringBg5 from '@/assets/images/solutions/suite-catering/bg-05.svg';

const apiData = {
  title: 'Mercedes-Benz Stadium',
  hotspots: {
    image: 'https://global-payments.popcomms.com/cms/media/pages/experiences/stadium/8e206b9b2f-1654769501/5c4b43f92bdd7f155664de15-1920x.jpg',
    markers: [
      {
        solution: '5dpex6vc',
        label: 'Food and beverage',
        x: 0.7793,
        y: 0.7535,
      },
      {
        solution: 'gfiv3q2j',
        label: 'Retail',
        x: 0.4979,
        y: 0.6285,
      },
      {
        solution: 'wf9dqyyn',
        label: 'Mobile purchasing',
        x: 0.7959,
        y: 0.2876,
      },
      {
        solution: 'ih49lslo',
        label: 'In-suite catering',
        x: 0.6469,
        y: 0.2648,
      },
      {
        solution: 's66tnikc',
        label: 'Digital menu boards',
        x: 0.6745,
        y: 0.4148,
      },
      {
        solution: 'jbyro0s7',
        label: 'Self-order kiosks',
        x: 0.269,
        y: 0.8489,
      },
      {
        solution: 'ithasp3r',
        label: 'Back office',
        x: 0.2966,
        y: 0.2989,
      },
      {
        solution: 'yth3o42m',
        label: 'Fan engagement',
        x: 0.48,
        y: 0.2921,
      },
    ],
  },
  products: [
    {
      uid: '3h9qd0s8',
      title: 'Xenial Display',
      description: '<p>Full color</p>',
      spec: [
        {
          key: '',
          value: 'Full Color',
          title: true,
        },
        {
          key: 'LCD Panel',
          value: '11.6” LED (eDP) / 300nits',
          title: false,
        },
        {
          key: 'Resolution',
          value: '1920 x 1080',
          title: false,
        },
        {
          key: 'Dimensions',
          value: '276.1 x 179.2 x 30.2 mm',
          title: false,
        },
      ],
      media: {
        image: null,
      },
    },
    {
      uid: 'pq1b6cnb',
      title: 'XC23 Terminal',
      description: '<ul>\n<li> The latest processors and chipsets for fast processing of orders and manager functions. </li>\n <li> Supports a range of peripherals.</li>\n <li>The motherboard, ports, and hard drive are all contained in one solid compartment.</li>\n <li>Fanless design to reduce noise and internal dust</li>\n </ul>',
      spec: [
        {
          key: 'System',
          value: 'Qualcomm 450 Octa-Core 1.8GHz 65 bit, 3GB LPDDR3 memory, 32GB eMMC storage',
          title: false,
        },
        {
          key: 'Display',
          value: '15.6” 16:9 1920x1080 (Full HD)<br>True-Flat PCAP Multi Touch',
          title: false,
        },
        {
          key: 'External I/O Ports',
          value: '1 x USB 3.0 Type C<br>2 x USB 3.0<br>2 x USB 2.0<br>2 x D89 (Powered RS232) (COM1OV<em>/5V, COM2 OV</em>/12V)<br>1 x RJ45<br>1 x RJ11<br>2 x FeDP (can convert to HDMI output)<br>1 x 4PIN DIN (with latch)',
          title: false,
        },
        {
          key: 'Dimensions',
          value: '9.2 x 6 x 1.3”<br>278 x 182 x 38.8 mm',
          title: false,
        },
        {
          key: 'Supported OS',
          value: 'Android 9.0',
          title: false,
        },
      ],
      media: {
        image: null,
      },
    },
    {
      uid: 'hexabgex',
      title: 'P400',
      description: '<p>Advanced, high performance PIN pad with rich multimedia and commerce capabilities.</p>\n<p>Our most advanced PIN pad, designed for performance and security. This premium device encourages greater customer engagement and better return.</p>',
      spec: [
        {
          key: 'Display',
          value: '3.5" HVGA color, capacitive touchscreen',
          title: false,
        },
        {
          key: 'Memory',
          value: '1024 MB (512 MB Flash, 512 MB SDRAM',
          title: false,
        },
        {
          key: 'Processor',
          value: '600 MHz, 1500 MIPS, Arm Cortex A9 32 bit RISC',
          title: false,
        },
        {
          key: 'Card Readers',
          value: 'Triple track MSR: ISO 7810, 7811, 7813; smart card reader: EMVCo approved',
          title: false,
        },
        {
          key: 'Operating System',
          value: 'V/OS (Linux based)',
          title: false,
        },
        {
          key: 'Contactless',
          value: 'SO 14443 Types A and B, ISO 18092 capable, EMVCo L1 certification, MIFARE, supports major NFC/CTLS schemes',
          title: false,
        },
        {
          key: 'Connectivity',
          value: '5 GHz + 2.4 GHz Wi Fi, Bluetooth® 4.2 low energy',
          title: false,
        },
        {
          key: 'Peripheral Ports',
          value: 'Single connector supports RS 232, USB device, USB host, Ethernet',
          title: false,
        },
        {
          key: 'Audio',
          value: 'Speaker',
          title: false,
        },
        {
          key: 'Physical',
          value: '167 mm L × 80 mm W × 44 mm H, 310 g',
          title: false,
        },
        {
          key: 'Power Supply',
          value: '5-12 V DC, 4.8 5.2 W power consumption, power over USB 5 V 500 mA',
          title: false,
        },
        {
          key: 'Environmental',
          value: 'Operating temperature: 0 to 50 C (32 to 122 F); storage temperature: 20 to 60 C ( 4 to 140 F); relative humidity: 5% to 90%, non condensing',
          title: false,
        },
        {
          key: 'Security',
          value: 'PCI PTS 5.X approved',
          title: false,
        },
        {
          key: 'Other Standard Features',
          value: '2 SAM slots, backlit keypad, uSD memory slot',
          title: false,
        },
      ],
      media: {
        image: null,
      },
    },
    {
      uid: '1ashrj19',
      title: 'e285',
      description: '<p>The Verifone e285 is an integrated mPOS device with WiFi and Bluetooth® communication options, allowing secure payment acceptance anywhere without dependence on a smart device. The solution features a color touchscreen capable of signature capture and is built on the Verifone Engage platform, enabling rich customer interaction at the point of sale.</p>',
      spec: [
        {
          key: 'Display',
          value: '2.8" (320 × 240) color capacitive touchscreen',
          title: false,
        },
        {
          key: 'Operating System',
          value: 'V/OS (Linux based)',
          title: false,
        },
        {
          key: 'OS Compatibility',
          value: 'iOS, Android, Windows',
          title: false,
        },
        {
          key: 'Processor',
          value: 'Arm Cortex A9, 32 bit RISC',
          title: false,
        },
        {
          key: 'Memory',
          value: '512MB Flash, 512MB DDR',
          title: false,
        },
        {
          key: 'Connectivity',
          value: 'Wireless: Bluetooth 4.2 with BLE, Wi Fi 2.4 GHz and 5GHZ 802.11 b, g, n, 3G optional<br>Wired: USB C connector supports charging and connection to iOS, Android, and Windows',
          title: false,
        },
        {
          key: 'Card Readers',
          value: 'Triple track MSR, EMV L1 approved smart card, ISO and NFC/contactless',
          title: false,
        },
        {
          key: 'Battery',
          value: '1800 mAh rechargeable, field replaceable',
          title: false,
        },
        {
          key: 'Keypad',
          value: 'Tactile, 15 keypad (ADA)',
          title: false,
        },
        {
          key: 'Environmental',
          value: 'Operating temperature: 0 to 50 C (32 to 122 F); operating relative humidity: 5% to 90%, non condensing; storage temperature: 20 to 60 C ( 4 to 140 F); storage relative humidity: 5% to 93%, non condensing',
          title: false,
        },
        {
          key: 'Physical',
          value: '138 mm L × 68 mm W × 18 mm H; 190 g',
          title: false,
        },
        {
          key: 'Security',
          value: 'PCI PTS 5.x',
          title: false,
        },
      ],
      media: {
        image: null,
      },
    },
    {
      uid: 'bq2cv2fp',
      title: 'Samsung Galaxy Tab Active Pro',
      description: '<p>Rugged tablet designed to withstand harsh conditions. S Pen and Enhanced Touch for note-taking and data input in inclement weather and when working with gloves on.</p>',
      spec: [
        {
          key: 'Size',
          value: '10.1"',
          title: false,
        },
        {
          key: 'Connection',
          value: 'WiFi',
          title: false,
        },
        {
          key: 'Processor',
          value: '2GHz, 1.7GHz Octa-Core',
          title: false,
        },
        {
          key: 'Battery',
          value: '7,600 mAh',
          title: false,
        },
        {
          key: 'OS',
          value: 'Android',
          title: false,
        },
      ],
      media: {
        image: null,
      },
    },
    {
      uid: 'wfcopqg5',
      title: 'Samsung QMR Series 50”',
      description: '<p>QM50R - Edge-Lit 4K UHD LED Display for Business (Cisco Certified Compatible Display)</p>\n<p>Experience exceptional 4K UHD non-glare picture quality with Dynamic Crystal Color for crystal clear, lifelike color.</p>\n<p><strong>Feature Overview</strong></p>\n<ul>\n<li>Slim and Symmetrical Design</li>\n<li>Dynamic Crystal Color</li>\n<li>Cisco Certified Compatible Display</li>\n<li>Incredible 4K UHD Non-Glare Picture Quality</li>\n<li>Intelligent UHD Upscaling</li>\n<li>HDR powered by HDR 10+</li>\n<li>Powerful, All-in-one Digital Signage Solution</li>\n<li>Built-in Wi-Fi and Bluetooth</li>\n<li>Elevate the Power of Your Displays</li>\n</ul>',
      spec: null,
      media: {
        image: null,
      },
    },
    {
      uid: 'z5emmj7i',
      title: 'MP10',
      description: '<p><strong>Feature/Benefit Overview</strong></p>\n<ul>\n<li>Dual HDMI Outputs</li>\n<li>Built In HDBaseT </li>\n<li>4K@40M /1080P@70M</li>\n<li>VESA Mount Capable</li>\n<li>Integrated Power Supply</li>\n</ul>\n<p>Available with Intel X86 CPUs</p>\n<ul>\n<li>Windows</li>\n<li>Linux</li>\n<li>Chromium</li>\n</ul>',
      spec: [
        {
          key: 'Motherboard',
          value: 'Intel Apollo-lake platform',
          title: false,
        },
        {
          key: 'Processor',
          value: 'Intel ApolloLake CPU J3455 2.3GHz, LLC 2M (10W)',
          title: false,
        },
        {
          key: 'Graphic engine',
          value: 'Intel HD Graphics 500',
          title: false,
        },
        {
          key: 'System Memory',
          value: 'DDR3L SO-DIMM up to 1866 MT/s, Max. up to 8GB',
          title: false,
        },
        {
          key: 'HDBaseT',
          value: 'Valens VS010 HDBasaT',
          title: false,
        },
        {
          key: 'LAN controller (GigaLAN)',
          value: 'Realtek 8111GN 10/100/1000 baceT LAN',
          title: false,
        },
        {
          key: 'Audio controller',
          value: 'Realtek AL C662VDO-GR',
          title: false,
        },
        {
          key: 'USB HUB',
          value: 'NEC UPD 720114GA',
          title: false,
        },
        {
          key: '',
          value: 'Internal Function Socket/Connector',
          title: true,
        },
        {
          key: 'Memory',
          value: '4GB SO-DIMM',
          title: false,
        },
        {
          key: 'Storage',
          value: '64 GB SATAIII SSD',
          title: false,
        },
        {
          key: 'Wireless LAN',
          value: '1x miniPCI-E Socket',
          title: false,
        },
        {
          key: '',
          value: 'Front I/O',
          title: true,
        },
        {
          key: 'Power Switch',
          value: '1',
          title: false,
        },
        {
          key: 'USB 3.0',
          value: '2 x USB Type A',
          title: false,
        },
        {
          key: '',
          value: 'Rear I/O',
          title: true,
        },
        {
          key: 'Power Switch',
          value: '1',
          title: false,
        },
        {
          key: 'USB 3.0',
          value: '2 x USB Type A',
          title: false,
        },
        {
          key: 'USB 2.0',
          value: '2 x USB Type A',
          title: false,
        },
        {
          key: 'Mic-in',
          value: '1',
          title: false,
        },
        {
          key: 'Line-out',
          value: '1',
          title: false,
        },
        {
          key: 'LAN',
          value: '2 x RJ-45 (10/100/1000 baseT)',
          title: false,
        },
        {
          key: 'Antenna Jack',
          value: '2',
          title: false,
        },
        {
          key: 'HDMI',
          value: '2',
          title: false,
        },
        {
          key: 'HDBaseT',
          value: '2 (optional HDMI capability only) *1080p/70m, 4k/40m',
          title: false,
        },
      ],
      media: {
        image: null,
      },
    },
    {
      uid: 'mnhrbw5y',
      title: 'X22',
      description: '<p>The wide array of transactional components housed in this sleek, feature-packed kiosk makes it one of the most powerful solutions on the market. It was meticulously engineered to accommodate portrait or landscape touchscreens, as well as a PIN pad and POS-style receipt printer. Its compact footprint and rugged security complement a variety of environments.</p>\n<p><strong>Specifications</strong></p>\n<ul>\n<li>22” All-in-One Computer•Capacitive multi-touch sensor</li>\n<li>Durable powder coat paint finish</li>\n<li>16-gauge galvanized steel</li>\n<li>Security locks</li>\n<li>High volume cooling fan</li>\n<li>Internal 6-outlet powerstrip w. surge protection</li>\n<li>Topple-resistant base plate (can bolt to ground)</li>\n<li>13”w x 54”h x 19”d</li>\n<li>Base plate: 17.5”w x 19”d</li>\n<li>Standard 110V/120V power</li>\n</ul>\n<p><strong>Optional Components</strong></p>\n<ul>\n<li>Ingenico IPP320 or IPP350 PIN Pad /Card Reader</li>\n<li>Verifone VX 805 PIN Pad / Card Reader</li>\n<li>Epson TMT-88VI thermal receipt printer</li>\n<li>Barcode scanner 1D, 2D, PDF, and QR</li>\n</ul>',
      spec: null,
      media: {
        image: null,
      },
    },
  ],
  solutions: [
    {
      uid: '5dpex6vc',
      title: 'Food and beverage',
      subtitle: 'Super-charge concession stand orders.',
      description: '<p>We’re making concession stand orders faster, easier, and more accurate. Send paperless receipts. Add We’re making concession stand orders faster, easier, and more accurate. Send paperless receipts. Add We’re making concession stand orders faster, easier, and more accurate. Send paperless receipts. Add We’re making concession stand orders faster, easier, and more accurate. Send paperless receipts. Add discounts. Sync data across the stadium—and beyond. Our cloud-based POS is ready to take on thousands of game day fans.</p>',
      technical: '<ul>\n<li>Concessions, hawking, bars/clubs, kiosk, delivery, and more: your choice of channels in one connected platform.</li>\n<li>Easy ordering at the bar with partial payments, split bills, named bar tabs, and more.</li>\n<li>Hardware and OS agnostic: Android, iOS, and Windows.</li>\n<li>Rugged design to withstand harsh conditions.</li>\n<li>Ensure order accuracy at high volumes thanks to a modern interface and off-the-charts ease of use.</li>\n<li>Easy to learn; quick download and set-up; automatic zero-touch updates.</li>\n</ul>',
      fact: {
        title: 'Hotdog half-marathon',
        description: '<p>Every year our fans eat enough hotdogs to stretch 13.1 miles. That’s over 330,000 franks—and a whole lot of mustard.</p>',
      },
      media: {
        icon: 'https://global-payments.popcomms.com/cms/media/pages/solutions/food-and-beverage/088fd105a1-1654860714/food-beverage-icon.svg',
        bg: [
          'https://global-payments.popcomms.com/cms/media/pages/solutions/food-and-beverage/06742087f3-1654860714/food-beverage-bg.svg',
        ],
      },
      related: [
        '3h9qd0s8',
        'pq1b6cnb',
      ],
    },
    {
      uid: 'gfiv3q2j',
      title: 'Retail',
      subtitle: 'Experience the best in retail.',
      description: '<p>Our EMV-enabled tech covers every store in the stadium. That means speedy and secure checkout. Choice of 140+ payment types. And seamlessly smooth processing. So fans can take home a great experience, as well as memories of the game.</p>',
      technical: '<ul>\n<li>Point of sale: Scalable, intuitive features in the cloud including: item lookup, customer dashboards, sales tax calculation and emailed receipts.</li>\n<li>Order entry, distribution, and fulfillment: Fulfill orders to multiple stores and channels and create special orders to manage all levels of complexity.</li>\n<li>Integrated shipping: Seamlessly compare rates between services, generate shipping labels, track packages and email customers.</li>\n<li>Returns and exchanges: Easily process returns and exchanges to integrate work and data flows across organization.</li>\n<li>Promotions and coupons: Create and report on tactical promotions around specific parameters.</li>\n<li>Gift cards: Import, store and manage all gift cards and gift card activity in the Heartland Retail platform with integrations.</li>\n<li>Inventory management: Manage your items seamlessly, get full visibility across multiple stores, and so much more.</li>\n</ul>',
      fact: {
        title: 'Record breaking sales',
        description: '<p>Mercedes-Benz Stadium smashed the record for most credit card transactions at a single football or soccer game. Just another reason Atlanta is the GOAT.</p>',
      },
      media: {
        icon: 'https://global-payments.popcomms.com/cms/media/pages/solutions/retail/3d89df2d85-1654860730/retail-icon.svg',
        bg: [
          'https://global-payments.popcomms.com/cms/media/pages/solutions/retail/7fcb01fc50-1654860730/retail-bg.svg',
        ],
      },
      related: [
        'hexabgex',
        '1ashrj19',
      ],
    },
    {
      uid: 'wf9dqyyn',
      title: 'Mobile purchasing',
      subtitle: 'Eat, drink, celebrate. Without moving an inch.',
      description: '<p>Browse, choose and pay—without missing a play. Fans can order food and drinks from their smartphones. And when the order’s ready, a notification shows the nearest pick-up point. Simple.</p>',
      technical: '<p>Fans can order food and drinks from their smartphones. And when the order’s ready, they can pick it up or have it delivered right to their seat. Mobile ordering provides an easy way for fans to order frictionlessly while providing you with an additional source of valuable customer data, as well as speeding up operations.</p>\n<ul>\n<li>Online/digital ordering and payments</li>\n<li>Push and in-app marketing</li>\n<li>Speedy operations</li>\n<li>Collect valuable fan data</li>\n<li>Loyalty programs  <ul>\n<li>Triggered promotions based on consumer transactions &amp; behavior</li>\n<li>Easy multi-source enrollment</li>\n<li>Flexible rewards programs - offers, $, points, visits</li>\n<li>Meaningful offers based on fan data</li>\n<li>Earn and redeem via card, phone number or mobile app</li>\n</ul>\n</li>\n</ul>',
      fact: {
        title: 'Deeper connection',
        description: '<p>Service is never on the sidelines here—you’re in the best-connected stadium in the country. All thanks to 1,800 wireless access points (over 500 more than any other NFL venue).</p>',
      },
      media: {
        icon: 'https://global-payments.popcomms.com/cms/media/pages/solutions/mobile-purchasing/6533070683-1654860749/mobile-purchasing-icon.svg',
        bg: [
          'https://global-payments.popcomms.com/cms/media/pages/solutions/mobile-purchasing/8c96887c96-1654860749/mobile-purchasing-bg.svg',
        ],
      },
      related: null,
    },
    {
      uid: 'ih49lslo',
      title: 'In-suite catering',
      subtitle: 'Suite perks—order now, customize later.',
      description: '<p>Choose food and drink ahead of the game. Order online anytime—from weeks ahead, to the ride here. Every suite attendant has a mobile POS connected to our stadium-wide system. So they can customize orders, even after kickoff.</p>',
      technical: '<p>Fans can place their order ahead of theirarrival with our online ordering capability.Or they can customize their order whileenjoying the game through the attendantwho uses our mobile point of sale tocapture orders in the suite. Whatever wayfans prefer their suite experience, we tailorit just for them.</p>\n<ul>\n<li>Order-ahead capabilities</li>\n<li>Custom orders</li>\n<li>Mobile POS</li>\n</ul>',
      fact: {
        title: 'Keep it suite',
        description: '<p>Enjoying the view? 190 suites around the stadium offer the best seats in the house. Perfect for all kinds of VIP.</p>',
      },
      media: {
        icon: 'https://global-payments.popcomms.com/cms/media/pages/solutions/in-suite-catering/53bf33f1fe-1654860764/in-suite-catering-icon.svg',
        bg: [
          'https://global-payments.popcomms.com/cms/media/pages/solutions/in-suite-catering/1e6aaa6ef8-1654860764/in-suite-catering-bg.svg',
        ],
      },
      related: [
        'bq2cv2fp',
      ],
    },
    {
      uid: 's66tnikc',
      title: 'Digital menu boards',
      subtitle: 'Feast your eyes on dynamic digital menus.',
      description: '<p>Grab attention with digital menu displays. Fans can see the latest food, drinks and promotions on screen—as text, photos, or high-resolution video. And easy automatic updates make changes in real time.</p>',
      technical: '<p>Dynamic and versatile displays capture fans and drive purchases.</p>\n<ul>\n<li>Centralized marketing at your fingertips</li>\n<li>Easily make menu and price updates</li>\n<li>Display promotions and nutrition info</li>\n<li>Centrally manage messages by event</li>\n<li>End-to-end solution</li>\n</ul>',
      fact: {
        title: 'Walk the line',
        description: '<p>With 800 points of sale around the stadium, our digital boards let you easily find what you’re in the mood for—so you don’t miss that vital play.</p>',
      },
      media: {
        icon: 'https://global-payments.popcomms.com/cms/media/pages/solutions/digital-menu-boards/9c79676679-1654860777/digital-menu-boards-icon.svg',
        bg: [
          'https://global-payments.popcomms.com/cms/media/pages/solutions/digital-menu-boards/332b3bde97-1654860777/digital-menu-boards-bg.svg',
        ],
      },
      related: [
        'wfcopqg5',
        'z5emmj7i',
      ],
    },
    {
      uid: 'jbyro0s7',
      title: 'Self-order kiosks',
      subtitle: 'Self-ordering—simple, speedy, smart.',
      description: '<p>Less time in line means more happy fans. Our self-order kiosks are easy to use, even for the trickiest orders. And daily menu changes are a breeze behind the scenes—all thanks to our stress-free software.</p>',
      technical: '<ul>\n<li>Improved speed of service: Self-order kiosks improve speed of service by up to 33%</li>\n<li>Increased profitability: Average ticket size improved by 20-25%. Increase in sales of high margin product mix.</li>\n<li>Improve labor efficiency and retention: Cashier labor reduced by a minimum of 10%. Primary ordering kiosks reduced employee turnover during Covid to near 0% in the QSR space.</li>\n<li>Fans control their orders: Self-service kiosks and grab-n-go reduce order inaccuracies to 0%. Employee and fan satisfaction increased over traditional ordering experience.</li>\n</ul>',
      fact: {
        title: 'Unlimited potential',
        description: '<p>Hear those cheers? Mercedes-Benz Stadium was the first major sports venue to offer unlimited self-serve soda. So walk up, top up, and get back to the game.</p>',
      },
      media: {
        icon: 'https://global-payments.popcomms.com/cms/media/pages/solutions/self-order-kiosks/f5520aeb1f-1654860790/self-order-kiosks-icon.svg',
        bg: [
          'https://global-payments.popcomms.com/cms/media/pages/solutions/self-order-kiosks/15b0c4ae4e-1654860790/self-order-kiosks-bg.svg',
        ],
      },
      related: [
        'mnhrbw5y',
      ],
    },
    {
      uid: 'ithasp3r',
      title: 'Back office',
      subtitle: 'Turning insights to action—in real time.',
      description: '<p>All of our solutions—inside the stadium, and out—are supported by our intelligent, data-driven ecosystem.</p>\n<p>Every transaction, purchase, and insight captured in a single, secure digital store. Driving visibility, peak efficiency, and impactful decisions.</p>',
      technical: '<p><strong>Back office event management and enterprise reporting:</strong></p>\n<ul>\n<li>Web based</li>\n<li>Unlimited users, stands, POS, staff</li>\n<li>Device agnostic including PC, mobile, tablet and more</li>\n<li>Dashboards</li>\n</ul>\n<p><strong>Inventory, food and cost management:</strong></p>\n<ul>\n<li>Transfers – warehouse to stand, stand to stand</li>\n<li>Send alerts via SMS</li>\n<li>Pricing can vary by event, stand; updated in real-time or scheduled</li>\n<li>Standsheets</li>\n<li>Inventory – food and beverage, retail</li>\n<li>Ordering, deliveries, returns, waste, transfers, management</li>\n</ul>\n<p><strong>Analytics and reporting:</strong></p>\n<ul>\n<li>Comprehensive standard reports</li>\n<li>Dimensional reporting – drill down to transaction level</li>\n<li>Design a report with sale wizard</li>\n<li>Export reports to Excel/pdf</li>\n<li>Send reports/alerts via SMS</li>\n</ul>',
      fact: {
        title: 'Serving it up',
        description: '<p>Smart, speedy and seamless. Mercedes-Benz Stadium holds the record for the most food and beverage transactions at a sports event. 120,357 to be exact—and we have the stats for every one.</p>',
      },
      media: {
        icon: 'https://global-payments.popcomms.com/cms/media/pages/solutions/back-office/3014517c97-1654860803/back-office-icon.svg',
        bg: [
          'https://global-payments.popcomms.com/cms/media/pages/solutions/back-office/95e92b76e6-1654860803/back-office-bg.svg',
        ],
      },
      related: null,
    },
    {
      uid: 'yth3o42m',
      title: 'Fan engagement',
      subtitle: 'Exceed fan expectations every time.',
      description: '<p>Stay connected and build loyalty by sending fans timely offers and easy-to-use rewards that they’ll love—right to their phone. Delight your fans and they’ll keep coming back for more.</p>',
      technical: '<p><strong>Customer intelligence</strong><br />\nYour fans’ transaction data fuels business intelligence.</p>\n<p><strong>Reputation</strong><br />\nIncrease revenue by understanding fan satisfaction in real time.</p>\n<p><strong>Fan analytics</strong><br />\nGain insights into fan purchasing behavior and average spend trends by harnessing payment data.</p>\n<p><strong>Target messaging/fan communication</strong><br />\nMake it easy for your venue to optimize fan communication.</p>\n<p><strong>Engaging promotions</strong><br />\nConnects you more individually with your customers via relevant and timely offers. </p>\n<p><strong>Trigger promotions</strong><br />\nDevelop loyal brand advocates</p>\n<p><strong>Gift card platform</strong><br />\nProvides a turnkey gift card platform that brands trust and fan love.</p>\n<p><strong>Discounts on F&amp;B and more</strong><br />\nDelight your staff and volunteers with discounts on food and beverage and more.</p>',
      fact: {
        title: 'Make it personal',
        description: '<p>Though the stadium sees 3 million guests come through the gates each year, tailored rewards sure make a fan feel special.</p>',
      },
      media: {
        icon: 'https://global-payments.popcomms.com/cms/media/pages/solutions/fan-engagement/7afc8270d4-1654860818/fan-engagement-icon.svg',
        bg: [
          'https://global-payments.popcomms.com/cms/media/pages/solutions/fan-engagement/149fe993a4-1654860819/fan-engagement-bg.svg',
        ],
      },
      related: null,
    },
  ],
};

const someData = [];

const solutionsMedia = [
  {
    uid: '5dpex6vc',
    title: 'Food and beverage',
    media: {
      bg: [
        foodAndBeverageBg1,
        foodAndBeverageBg2,
        foodAndBeverageBg3,
        foodAndBeverageBg4,
        foodAndBeverageBg5,
      ],
    },
  },
  {
    uid: 'gfiv3q2j',
    title: 'Retail',
    media: {
      bg: [
        retailBg1,
        retailBg2,
        retailBg3,
        retailBg4,
        retailBg5,
      ],
    },
  },
  {
    uid: 'wf9dqyyn',
    title: 'Mobile purchasing',
    media: {
      bg: [
        mobilePurchasingBg1,
        mobilePurchasingBg2,
        mobilePurchasingBg3,
        mobilePurchasingBg4,
        mobilePurchasingBg5,
      ],
    },
  },
  {
    uid: 'ih49lslo',
    title: 'In-suite catering',
    media: {
      bg: [
        suiteCateringBg1,
        suiteCateringBg2,
        suiteCateringBg3,
        suiteCateringBg4,
        suiteCateringBg5,
      ],
    },
  },
  {
    uid: 's66tnikc',
    title: 'Digital menu boards',
    media: {
      bg: [
        menuBoardsBg1,
        menuBoardsBg2,
        menuBoardsBg3,
        menuBoardsBg4,
        menuBoardsBg5,
      ],
    },
  },
  {
    uid: 'jbyro0s7',
    title: 'Self-order kiosks',
    media: {
      bg: [
        selfServiceBg1,
        selfServiceBg2,
        selfServiceBg3,
        selfServiceBg4,
        selfServiceBg5,
      ],
    },
  },
  {
    uid: 'ithasp3r',
    title: 'Back office',
    media: {
      bg: [
        backOfficeBg1,
        backOfficeBg2,
        backOfficeBg3,
        backOfficeBg4,
        backOfficeBg5,
      ],
    },
  },
  {
    uid: 'yth3o42m',
    title: 'Fan engagement',
    media: {
      bg: [
        fanEngagementBg1,
        fanEngagementBg2,
        fanEngagementBg3,
        fanEngagementBg4,
        fanEngagementBg5,
      ],
    },
  },
];

const factCoords = [
  {
    uid: '5dpex6vc',
    title: 'Food and beverage',
    fact: {
      position: {
        x: 0.65,
        y: 0.2,
      },
    },
  },
  {
    uid: 'gfiv3q2j',
    title: 'Retail',
    fact: {
      position: {
        x: 0.65,
        y: 0.2,
      },
    },
  },
  {
    uid: 'wf9dqyyn',
    title: 'Mobile purchasing',
    fact: {
      position: {
        x: 0.1600,
        y: 0.4500,
      },
    },
  },
  {
    uid: 'ih49lslo',
    title: 'In-suite catering',
    fact: {
      position: {
        x: 0.110,
        y: 0.4,
      },
    },
  },
  {
    uid: 's66tnikc',
    title: 'Digital menu boards',
    fact: {
      position: {
        x: 0.6,
        y: 0.12,
      },
    },
  },
  {
    uid: 'jbyro0s7',
    title: 'Self-order kiosks',
    fact: {
      position: {
        x: 0.1612,
        y: 0.5000,
      },
    },
  },
  {
    uid: 'ithasp3r',
    title: 'Back office',
    fact: {
      position: {
        x: 0.1340,
        y: 0.1700,
      },
    },
  },
  {
    uid: 'yth3o42m',
    title: 'Fan engagement',
    fact: {
      position: {
        x: 0.26,
        y: 0.2,
      },
    },
  },
];

export {
  apiData,
  someData,
  solutionsMedia,
  factCoords,
};
