import { VueOfflineStorage } from 'vue-offline';

const mutations = {
  select: 'SELECT',
  selectMany: 'SELECT_MANY',
};

const actions = {
  select: 'SELECT',
  selectMany: 'SELECT_MANY',
};

const getters = {
  selected: 'selected',
};

const cacheKey = 'selected';

const addToCache = async (data) => {
  VueOfflineStorage.set(cacheKey, data);
};

const selectedCached = VueOfflineStorage.get(cacheKey);

const module = {
  state: {
    selected: selectedCached || [],
  },
  mutations: {
    [mutations.select](state, id) {
      const isAlreadySelected = state.selected.find((selectedId) => selectedId === id);

      if (isAlreadySelected) {
        state.selected = state.selected.filter((sId) => sId !== id);
      } else {
        state.selected.push(id);
      }
    },
    [mutations.selectMany](state, selected) {
      state.selected = selected;
    },
  },
  actions: {
    async [actions.select]({ commit, state }, id) {
      commit(mutations.select, id);
      await addToCache(state.selected);
    },
    async [actions.selectMany]({ commit }, data) {
      commit(mutations.selectMany, data);
      await addToCache(data);
    },
  },
  getters: {
    [getters.selected](state) {
      return state.selected;
    },
  },
};

export default {
  module,
  actions,
  getters,
};
