const mutations = {
  showLightIcon: 'SHOW_LIGHT_ICON',
};

const actions = {
  showLightIcon: 'SHOW_LIGHT_ICON',
};

const getters = {
  showLightIcon: 'showLightIcon',
};

const module = {
  state: {
    showLightIcon: false,
  },
  mutations: {
    [mutations.showLightIcon](state, showLightIcon) {
      state.showLightIcon = showLightIcon;
    },
  },
  actions: {
    [actions.showLightIcon]({ commit }, showLightIcon) {
      commit(mutations.showLightIcon, showLightIcon);
    },
  },
  getters: {
    [getters.showLightIcon](state) {
      return state.showLightIcon;
    },
  },
};

export default {
  module,
  actions,
  getters,
};
