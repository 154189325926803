const component = () => import('./concourse-view.view.vue');

export default [
  {
    path: '/concourse-view',
    name: 'concourse-view.index',
    component,
    props: true,
    meta: {
      title: 'Concourse View',
      transition: 'fade-up',
    },
  },
];
