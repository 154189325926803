import { VueOfflineStorage } from 'vue-offline';
import { auth as authApi } from '@/services/api';
import { solutionsMedia, factCoords } from '../fake-data';

const mutations = {
  loadContent: 'LOAD_CONTENT',
};

const actions = {
  loadContent: 'LOAD_CONTENT',
};

const getters = {
  experienceUid: 'experienceUid',
  hotspots: 'hotspots',
  solutions: 'solutions',
  products: 'products',
  explore: 'explore',
  contact: 'contact',
  screensaver: 'screensaver',
  modelJson: 'modelJson',
};

const cacheKey = 'content';

const addToCache = async (data) => {
  VueOfflineStorage.set(cacheKey, data);
  if (!caches) {
    return;
  }
  const solutionsContent = data.solutions.reduce((result, current) => [
    ...result,
    current.media.icon,
    ...current.media.bg,
  ], []);

  const productsContent = data.products.reduce((result, current) => (current.media.image
    ? [
      ...result,
      current.media.image,
    ] : result), []);
  const urls = [
    ...solutionsContent,
    ...productsContent,
    data.hotspots.image,
    data.pages.explore.video,
    data.pages.explore.model.json,
  ];
  if (urls.length > 0) {
    setTimeout(async () => {
      const cache = await caches.open(cacheKey);
      await cache.addAll(urls);
    }, 0);
  }
};

const getContent = async () => {
  const response = await fetch(
    process.env.VUE_APP_API_CONTENT_PATH, {
      method: 'GET',
      headers: authApi.getAuthHeader(),
    },
  );
  return response.json();
};

const cachedContent = VueOfflineStorage.get(cacheKey);
const module = {
  state: {
    content: cachedContent || {},
  },
  getters: {
    [getters.experienceUid](state) {
      return state.content.uid;
    },
    [getters.hotspots](state) {
      return state.content.hotspots;
    },
    [getters.solutions](state) {
      const solutions = state.content.solutions
        .map((s) => ({
          ...s,
          media: {
            ...s.media,
            bg: solutionsMedia.find((solution) => solution.uid === s.uid).media.bg,
          },
        }))
        .map((s) => ({
          ...s,
          fact: {
            ...s.fact,
            ...factCoords.find((solution) => solution.uid === s.uid).fact,
          },
        }));

      const orderedSolutions = [
        solutions.find((s) => s.uid === 'gfiv3q2j'),
        solutions.find((s) => s.uid === 'jbyro0s7'),
        solutions.find((s) => s.uid === 'wf9dqyyn'),
        solutions.find((s) => s.uid === '5dpex6vc'),
        solutions.find((s) => s.uid === 'yth3o42m'),
        solutions.find((s) => s.uid === 's66tnikc'),
        solutions.find((s) => s.uid === 'ih49lslo'),
        solutions.find((s) => s.uid === 'ithasp3r'),
      ];

      return orderedSolutions;
    },
    [getters.products](state) {
      return state.content.products;
    },
    [getters.explore](state) {
      return state.content.pages.explore;
    },
    [getters.contact](state) {
      return state.content.pages.contact;
    },
    [getters.screensaver](state) {
      return state.content.pages.screensaver;
    },
    [getters.modelJson](state) {
      return state.content.pages.explore.model.json;
    },
  },
  mutations: {
    [mutations.loadContent](state, content) {
      state.content = content;
    },
  },
  actions: {
    async [actions.loadContent]({ commit }) {
      try {
        const data = await getContent();
        commit(mutations.loadContent, data);
        await addToCache(data);
      } catch (error) {
        console.log(error);
      }
    },
  },
};

export default {
  module,
  actions,
  getters,
};
