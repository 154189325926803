import Vue from 'vue';
import Vuex from 'vuex';
import contentModule from './modules/content.module';
import navigationModule from './modules/navigation.module';
import contactFormModule from './modules/contact-form.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    content: contentModule.module,
    navigation: navigationModule.module,
    contactForm: contactFormModule.module,
  },
});

const actions = {
  content: contentModule.actions,
  navigation: navigationModule.actions,
  contactForm: contactFormModule.actions,
};

const getters = {
  content: contentModule.getters,
  navigation: navigationModule.getters,
  contactForm: contactFormModule.getters,
};

export { actions, getters };
