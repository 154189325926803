<template>
  <div class="w-full h-full relative">
    <canvas
      class="absolute"
      ref="sceneContainer"
      :style="{width: viewport.width, height: viewport.height}"
    />
    <div class="w-full h-full" v-if="xhr.ready">
      <v-hotspot-wrapper
        v-for="(hotspot, hotspotIndex) in hotspotsList"
        :key="hotspotIndex"
        :hotspot="hotspot"
      />
    </div>
  </div>
</template>
<script>
import * as THREE from 'three';
import CameraControls from 'camera-controls';
import { mapGetters } from 'vuex';
import { getters } from '@/store';
import HotspotWrapper from './hotspot-wrapper.vue';

const loader = new THREE.ObjectLoader();
CameraControls.install({ THREE });

let renderer;
let clock;
let scene;
let controls;
let disposed = false;

export default {
  name: 'Stadium',
  components: {
    VHotspotWrapper: HotspotWrapper,
  },
  data() {
    return {
      active: null,
      camera: null,
      mouse: null,
      raycaster: null,
      gltfModel: null,
      disableAutoRotate: false,
      viewport: {
        height: null,
        devicePixelRatio: null,
        width: null,
      },
      xhr: {
        loaded: 0,
        total: 0,
        ready: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      getters.content.modelJson,
      getters.content.hotspots,
      getters.content.solutions,
    ]),
    hotspotsList() {
      const selectedMarkers = this.hotspots.markers;
      const hotspots = selectedMarkers.map((m) => ({
        ...this.solutions.find((s) => s.uid === m.solution).media,
        ...m,
      }));
      return hotspots;
    },
  },
  methods: {
    init() {
      disposed = false;
      this.setViewport();
      this.camera = new THREE
        .PerspectiveCamera(45, this.viewport.width / this.viewport.height, 1, 1000);
      this.mouse = new THREE.Vector2();
      this.raycaster = new THREE.Raycaster();

      renderer = new THREE.WebGLRenderer({
        antialias: true,
        canvas: this.$refs.sceneContainer,
      });
      renderer.setPixelRatio(this.viewport.devicePixelRatio);
      renderer.setSize(this.viewport.width, this.viewport.height);
      renderer.shadowMap.enabled = true;
      renderer.toneMapping = THREE.LinearToneMapping;
      renderer.outputEncoding = THREE.GammaEncoding;
      renderer.gammaFactor = 1.8;

      clock = new THREE.Clock();
      controls = new CameraControls(this.camera, this.$refs.sceneContainer);
      controls.minPolarAngle = 0.9220699226456553;
      controls.maxPolarAngle = 0.9220699226456553;
      controls.minDistance = 470;
      controls.maxDistance = 470;
      controls.touches.two = 0;

      controls.addEventListener('control', this.switchAutoRotation);

      loader.load(this.modelJson,
        (obj) => {
          scene = obj;
          scene.background = '#FFFFFF';
          this.gltfModel = scene;
          this.animate();
          this.reset();
        },
        (xhr) => {
          this.xhr.loaded = xhr.loaded;
          this.xhr.total = xhr.total;
          this.$emit('progress', ((xhr.loaded * 100) / xhr.total));
          if (xhr.loaded >= xhr.total) {
            this.xhr.ready = true;
            this.$emit('ready', setTimeout(() => true), 200);
          }
        },
        (err) => {
          console.error(err);
        });
    },
    animate() {
      if (disposed) {
        return;
      }
      const delta = clock.getDelta();
      this.raycaster.setFromCamera(this.mouse, this.camera);
      if (this.viewport.width < 1400) {
        this.setDollyTo(540);
      } else {
        this.setDollyTo(470);
      }
      controls.update(delta);
      if (!this.disableAutoRotate) {
        controls.azimuthAngle += 1 * delta * THREE.MathUtils.DEG2RAD;
      }
      window.requestAnimationFrame(this.animate);
      renderer.render(scene, this.camera);
    },
    switchAutoRotation() {
      this.disableAutoRotate = true;
    },
    reset() {
      controls.setPosition(244, 185, 0, true);
    },
    resize() {
      this.viewport.width = window.innerWidth;
      this.viewport.height = window.innerHeight;
      this.camera.aspect = this.viewport.width / this.viewport.height;
      this.camera.updateProjectionMatrix();
      renderer.setSize(this.viewport.width, this.viewport.height);
      this.animate();
    },
    setDollyTo(distance) {
      controls.minDistance = distance;
      controls.maxDistance = distance;
      controls.dollyTo(distance, true);
    },
    setViewport() {
      this.viewport.width = window.innerWidth;
      this.viewport.height = window.innerHeight;
      this.viewport.devicePixelRatio = window.devicePixelRatio;
    },
  },
  mounted() {
    this.init();
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
    controls.removeEventListener('control', this.switchAutoRotation);
    const sceneTraverse = (obj, fn) => {
      if (!obj) return;

      fn(obj);

      if (obj.children && obj.children.length > 0) {
        obj.children.forEach((o) => {
          sceneTraverse(o, fn);
        });
      }
    };

    sceneTraverse(scene, (o) => {
      if (o.geometry) {
        o.geometry.dispose();
      }

      if (o.material) {
        if (o.material.length) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < o.material.length; ++i) {
            o.material[i].dispose();
          }
        } else {
          o.material.dispose();
        }
      }
    });

    clock = null;
    controls = null;
    disposed = true;
    scene = null;
    // eslint-disable-next-line no-unused-expressions
    renderer && renderer.renderLists.dispose();
    renderer = null;
  },
};
</script>
