<template>
  <button
    class="btn-expand"
    :class="{'btn-expand--reversed': reversed}"
    @click="$emit('clicked')"
  >
    <slot />

    <span class="btn-expand__icon" />
  </button>
</template>

<script>
export default {
  name: 'ButtonExpand',
  props: {
    reversed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_mixins';
@import '@/assets/scss/_variables';

.btn-expand {
  position: absolute;
  display: inline-block;
  border: 1px solid #fff;
  padding: 0.25rem 3rem 0.25rem 1.25rem;
  background-color: $color-white;
  filter: drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.25));
  font-size: 1rem;
  font-weight: 400;
  color: $color-black;

  @include respond-below(lg) {
    padding: 0.2rem 2rem 0.2rem 0.75rem;
    font-size: 0.9rem;
  }

  &:hover,
  &:focus,
  &:active {
    .btn-expand__icon {
      transform: translate(50%, -50%) scale(1.05);
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%) scale(1.01);
    border: 3px solid #fff;
    border-radius: 50%;
    width: 3.3rem;
    height: 3.3rem;
    background: $color-green url(../assets/images/icons/icon-plus.svg) no-repeat center / 50%;
    transition: all 0.3s ease;

    @include respond-below(lg) {
      width: 2.75rem;
      height: 2.75rem;
    }
  }

  &--reversed {
    padding: 0.25rem 1.25rem 0.25rem 3rem;

    @include respond-below(lg) {
      padding: 0.2rem 0.75rem 0.2rem 2rem;
      font-size: 0.9rem;
    }

    &:hover,
    &:focus,
    &:active {
      .btn-expand__icon {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }

    .btn-expand__icon {
      right: auto;
      left: 0;
      transform: translate(-50%, -50%) scale(1.01);
    }
  }
}
</style>
